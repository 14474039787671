import React, { memo } from "react";
import useTranslation from "next-translate/useTranslation";
import useReadMore from "hooks/useReadmore";
import SafelySetInnerHTML from "components/Render/HTML.render";

type Props = {
	businessName: string;
	description: string;
};

function Info({ businessName, description }: Props) {
	const { t } = useTranslation("common");
	const [truncated, hasLimit, helperText, onClickButton, isReadMore] = useReadMore(description || "", 500);
	return (
		<>
			<h2 className="font-medium text-lg xl:text-xl mb-4">
				{t("navigation.about")} {businessName}
			</h2>
			<p className="text-sm leading-6 xl:text-base mb-6 text-[#46474a]">
				<SafelySetInnerHTML htmlText={truncated} />
				{hasLimit && !isReadMore && "..."}{" "}
				{hasLimit && (
					<button
						type="button"
						onClick={onClickButton}
						className="text-primary-500 hover:text-primary-300 underline"
					>
						{helperText}
					</button>
				)}
			</p>
		</>
	);
}

export default memo(Info);
